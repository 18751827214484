import './Letter.css'
import { useState } from 'react'
import { alphabet } from './config'

function Letter(props) {

    const statuses = [
        'grey',
        'yellow',
        'green',
    ]

    const [status, setStatus] = useState(statuses[0])
    const [letter, setLetter] = useState(props.letter.letter)

    const handleToggle = (e) => {
        let cur = statuses.indexOf(status)
        const n = cur < (statuses.length - 1) ? cur + 1 : 0
        setStatus(statuses[n])
        props.onStatusChange(statuses[n], props.position)
    }

    const handleLetterChange = (e) => {
        let value = e.target.value.toLowerCase()

        if(alphabet.indexOf(value) === -1) {
            value = ''
        }

        setLetter(value)
        props.onLetterChange(value, props.position)
    
        if(!value) {
            e.target.value = ''
            setStatus('grey')
        }
    }

    const classes = [
        'Letter',
        'Letter__' + status,
    ]

    return (
        <div className={classes.join(' ')}>
            <input type="text" className="Letter-input" maxLength="1" onChange={handleLetterChange}></input>
            <button className="Letter-toggle" onClick={handleToggle}>Toggle</button>
        </div>
    );
}

export default Letter