import './App.css';
import Word from './Word'
import Footer from './Footer'
import PossibleWords from './PossibleWords'
import ExcludedLetters from './ExcludedLetters'
import { accepted } from './wordlist'
import { useState } from 'react'

function App() {

  const [word, setWord] = useState([])
  const [possibleWords, setPosibleWords] = useState(accepted)
  const [excludedLetters, setExcludedLetters] = useState([])

  const filterWords = (userWord, exclusions, word) => {
    const tokenised = [...word].map(letter => letter.toLowerCase())
    let matches = []

    const containsExcluded = tokenised.filter(letter => exclusions.includes(letter))
    if(containsExcluded.length > 0) {
      return false
    }

    userWord.forEach((letter, index) => {
      const found = word.indexOf(letter.letter)

      if(
        (letter.status === 'green' && tokenised[index] === letter.letter) ||
        (letter.status === 'yellow' && found > -1 && found !== index)
      ) {
        matches.push(true)
      }

      return true
    })

    return matches.length === userWord.filter(w => w.letter !== '').length
  }

  const onWordChange = (userWord) => {
    setPosibleWords(
      accepted.filter(filterWords.bind(filterWords, userWord, excludedLetters))
    )
    setWord(userWord)
  }

  const onLetterChange = (letters) => {
    setPosibleWords(
      accepted.filter(filterWords.bind(filterWords, word, letters))
    )
    setExcludedLetters(letters)
  }

  return (
    <div className="App">
      <Word count="5" onWordChange={onWordChange}></Word>
      <ExcludedLetters onLetterChange={onLetterChange}></ExcludedLetters>
      <PossibleWords words={possibleWords}></PossibleWords>
      <Footer></Footer>
    </div>
  );
}

export default App;
