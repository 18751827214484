import './Word.css'
import Letter from './Letter'
import { useState } from 'react'

function Word(props) {

    const defaultLetter = {
        letter: '',
        status: '',
    }

    const count = parseInt(props.count)
    const defaultWord = new Array(count).fill(defaultLetter)
    const [word, setWord] = useState(defaultWord)

    const updateWord = (position, change) => {
        const clonedWord = [...word]
        clonedWord[position] = {
            ...clonedWord[position],
            ...change,
        }

        setWord(clonedWord)
        props.onWordChange(clonedWord)
    }

    const onLetterChange = (letter, position) => {
        updateWord(position, { letter })
    }

    const onStatusChange = (status, position) => {
        updateWord(position, { status })
    }

    const letterComponents = []
    defaultWord.forEach((letter, i) => {
        letterComponents.push(<Letter key={i} position={i} letter={letter} onLetterChange={onLetterChange} onStatusChange={onStatusChange}></Letter>)
    })

    return (
        <div>
            <h6>Confirmed Letters</h6>
            <div className="Word">{letterComponents}</div>
        </div>
    );
}

export default Word