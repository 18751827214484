import './PossibleWords.css'

function PossibleWords(props) {

    const { words } = props

    const wordList = []
    words.forEach((word, index) => {
      wordList.push(<li key={index}>{word}</li>)
    })

    return (
        <details className="PossibleWords">
            <summary>{words.length} possible words</summary>
            <ul>{wordList}</ul>
        </details>
    );
}

export default PossibleWords
