import './ExcludedLetters.css'
import { useState } from 'react'
import { alphabet } from './config'

function ExcludedLetters(props) {

    const [letters, setLetters] = useState([])

    const toggleLetter = (letter) => {
        const clonedLetters = [...letters]
        const index = clonedLetters.indexOf(letter)
        index > -1 ? clonedLetters.splice(index, 1) : clonedLetters.push(letter)
        setLetters(clonedLetters)
        props.onLetterChange(clonedLetters)
    }

    const letterElements = []
    alphabet.forEach((letter, index) => {
        const state = (letters.indexOf(letter) > -1) ? 'active' : 'inactive'
        const className = [
            'ExcludedLetter',
            'ExcludedLetter-' + state,
        ]

        letterElements.push(<button key={index} className={className.join(' ')} onClick={() => toggleLetter(letter)}>{letter}</button>)
    })

    return (
        <div className="ExcludedLetters">
            <h6>Excluded Letters</h6>
            <div>{letterElements}</div>
        </div>
    )
}

export default ExcludedLetters
